import React from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { takeLatest, put } from "redux-saga/effects";
import { GET, PUT } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { history } from "helpers/sharedHelpers";
import { style } from "style";
import {
  GET_USER,
  GET_NOT_VIEWED_NOTIFICATIONS,
  GET_NOT_VIEWED_NOTIFICATIONS_SIZE,
  GET_TERMS,
  PUT_PASSWORD,
  GET_POLICIES_PRIVACY,
  GET_PERSONAL_QUESTIONS,
  GET_TOKEN_TUTORIAL,
  DELETE_USER_ACCOUNT,
  GET_USER_ANSWER,
  GET_MOOD_SURVEY_CURRENT,
  POST_MOOD_SURVEY_ANSWER,
  VISUALIZATION_MOOD_SURVEY_CURRENT,
} from "utils/constants/endpoints";
import urls from "utils/constants/urls";
import { Types } from "../ducks/user";
import { Types as AlertTypes } from "../ducks/modalAlert";
import { API_V2 } from "../../utils/API/API";

function* updatePassword({ payload }) {
  const idUser = localStorage.getItem("idUser");
  const body = { password: payload.new, current_password: payload.current };
  try {
    yield requestAPI({
      verb: PUT,
      endPoint: PUT_PASSWORD,
      id: idUser,
      data: body,
    });
    yield put({
      type: Types.UPDATE_PASSWORD_SUCCESS,
    });
    yield put({
      type: Types.GET_USER_REQUEST,
    });

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "success",
        title: <FormattedMessage id="modal.textPage.successModal" />,
        msg: "Sua senha foi atualizada!",
        buttons: [
          {
            title: "OK",
            event: () => window.location.reload(true),
          },
        ],
      },
    });
  } catch (error) {
    const currentPassError = error.response.data.errors
      ? error.response.data.errors[0] ===
        "A validação falhou: Senha atual não é válido"
      : false;

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: "Não foi possivel alterar a senha, tente novamente",
      },
    });

    yield put({
      type: Types.UPDATE_PASSWORD_FAIL,
      currentPassError,
    });
  }
}

function* updateDataUser({ payload }) {
  const isToSuvinilResearch = payload.isToSuvinilResearch;
  const isAddressConfirmation = payload.isAddressConfirmation;
  delete payload.isToSuvinilResearch;
  delete payload.isAddressConfirmation;
  const entries = Object.entries(payload);
  const idUser = localStorage.getItem("idUser");
  const formData = new FormData();
  const contacts_ids_to_delete = [];
  // eslint-disable-next-line
  entries.map((item) =>
    item[0] === "contacts" // eslint-disable-next-line
      ? item[1].map((item, i) => {
          if (item.id) {
            if (item.contact === "" && !style.variables.externalUse) {
              contacts_ids_to_delete.push(item.id);
              formData.append(`[contacts_ids_to_delete][${i}]`, item.id);
            } else {
              formData.append(`[contacts_attributes][${i}][id]`, item.id);
              formData.append(`[contacts_attributes][${i}][kind]`, item.kind);
              formData.append(
                `[contacts_attributes][${i}][contact]`,
                item.contact,
              );
            }
          } else {
            formData.append(`[contacts_attributes][${i}][kind]`, item.kind);
            formData.append(
              `[contacts_attributes][${i}][contact]`,
              item.contact,
            );
          }
        })
      : item[0] == "address"
      ? (item[1].zip_code !== "" &&
          formData.append(`address[zip_code]`, item[1].zip_code),
        item[1].street !== "" &&
          formData.append(`address[street]`, item[1].street),
        item[1].complement !== "" &&
          formData.append(`address[complement]`, item[1].complement),
        item[1].neighborhood !== "" &&
          formData.append(`address[neighborhood]`, item[1].neighborhood),
        item[1].number !== "" &&
          formData.append(`address[number]`, item[1].number),
        item[1].uf !== "" && formData.append(`address[uf]`, item[1].uf),
        item[1].city !== "" && formData.append(`address[city]`, item[1].city))
      : /* item[1] !== "" &&  */ formData.append(item[0], item[1]),
  );
  try {
    yield requestAPI({
      verb: PUT,
      endPoint: GET_USER,
      id: idUser,
      data: formData,
      contacts_ids_to_delete,
    });

    if (!isToSuvinilResearch && !isAddressConfirmation)
      yield put({
        type: AlertTypes.SET_ALERTS_REQUEST,
        messages: {
          type: "success",
          title: <FormattedMessage id="modal.textPage.successModal" />,
          msg: "Seu perfil foi atualizado!",
          buttons: [
            {
              title: "OK",
              event: () => window.location.reload(true),
            },
          ],
        },
      });

    yield put({
      type: Types.UPDATE_DATA_USER_SUCCESS,
      updateUserInaddressConfirmation: isAddressConfirmation,
    });
  } catch (error) {
    yield put({
      type: Types.UPDATE_DATA_USER_FAIL,
      ...error.response.data,
    });
    if (isToSuvinilResearch)
      yield put({
        type: AlertTypes.SET_ALERTS_REQUEST,
        messages: {
          type: "errors",
          title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
          msg: "Por algum motivo não conseguimos salvar sua resposta. Por favor, tente novamente mais tarde. ",
          buttons: [
            {
              title: "OK",
              event: () => window.location.reload(true),
            },
          ],
        },
      });
  }
}

function* getUserRequest() {
  try {
    const idUser = localStorage.getItem("idUser");
    const user = yield requestAPI({
      verb: GET,
      endPoint: GET_USER,
      id: idUser,
    });
    localStorage.setItem("roles", user.roles[0].name);
    yield put({
      type: Types.GET_NOT_VIEWD_NOTIFICATIONS,
    });
    yield put({
      user,
      type: Types.GET_USER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_USER_FAIL,
      ...error.response.data,
    });
  }
}

function* getUserToEditRequest() {
  try {
    const idUser = localStorage.getItem("idUser");
    const user = yield requestAPI({
      verb: GET,
      endPoint: GET_USER,
      id: idUser,
    });

    yield put({
      user,
      type: Types.GET_USER_SUCCESS,
    });

    yield put({
      type: Types.GET_PERSONAL_QUESTIONS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_USER_FAIL,
      ...error.response.data,
    });
  }
}

function* getTerms() {
  try {
    const terms = yield requestAPI({
      verb: GET,
      endPoint: GET_TERMS,
    });
    yield put({
      terms,
      type: Types.GET_TERMS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_TERMS_FAIL,
      ...error.response.data,
    });
  }
}

function* getPrivacyPolicies() {
  try {
    const privacyPolicies = yield requestAPI({
      verb: GET,
      endPoint: GET_POLICIES_PRIVACY,
    });
    yield put({
      privacyPolicies,
      type: Types.GET_PRIVACY_POLICIES_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRIVACY_POLICIES_FAIL,
      ...error.response.data,
    });
  }
}

function* getPersonalQuestions() {
  try {
    const personalQuestions = yield requestAPI({
      verb: GET,
      endPoint: GET_PERSONAL_QUESTIONS,
    });
    yield put({
      personalQuestions: personalQuestions.personal_questions,
      type: Types.GET_PERSONAL_QUESTIONS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PERSONAL_QUESTIONS_FAIL,
      ...error.response.data,
    });
  }
}

function* getTutorialToken() {
  try {
    const { token } = yield requestAPI({
      verb: GET,
      endPoint: GET_TOKEN_TUTORIAL,
    });
    yield put({
      token,
      type: Types.GET_TUTORIAL_TOKEN_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: `${error.response.data.errors}`,
        buttons: [
          {
            title: "OK",
            event: () => window.location.assign(urls.ROUTES.CONFIGURATIONS),
          },
        ],
      },
    });
    yield put({
      type: Types.GET_TUTORIAL_TOKEN_FAIL,
      ...error.response.data,
    });
  }
}

function* getNotViewdNotifications() {
  try {
    const {
      data: { size },
    } = yield API_V2({
      url: GET_NOT_VIEWED_NOTIFICATIONS_SIZE,
      method: "GET",
    });

    yield put({
      notViewdNotifications: size,
      type: Types.GET_NOT_VIEWD_NOTIFICATIONS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_NOT_VIEWD_NOTIFICATIONS_FAIL,
      ...error.response.data,
    });
  }
}

function* deleteUserAccount() {
  try {
    const { status } = yield API_V2({
      url: `${DELETE_USER_ACCOUNT}`,
      method: "PATCH",
    });

    if (status === 200) {
      const messages = {
        title: <FormattedMessage id="modal.textPage.deletedAccount" />,
        msg: <FormattedMessage id="modal.textPage.deletedAccountInfo" />,
        buttons: [
          {
            title: <FormattedMessage id="general.buttons.ok" />,
            event: () => history.push("/logout"),
          },
        ],
      };
      yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    }
  } catch (error) {
    const messages = {
      type: "errors",
      title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
      msg: error.response.data[Object.keys(error.response.data)],
    };
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
  }
}

function* getUserAnswer() {
  try {
    const {
      data: { user_answer, mood_survey },
    } = yield API_V2({
      url: `${GET_USER_ANSWER}`,
      method: "GET",
    });

    yield put({
      type: Types.GET_USER_ANSWER_SUCCESS,
      userAnswer: user_answer,
      moodSurveyPreview: mood_survey,
    });
  } catch (error) {
    yield put({
      type: Types.GET_USER_ANSWER_FAIL,
      ...error.response.data,
    });
  }
}

function* getMoodSurveyCurrent() {
  try {
    const { data } = yield API_V2({
      url: `${GET_MOOD_SURVEY_CURRENT}`,
      method: "GET",
    });

    yield put({
      type: Types.GET_MOOD_SURVEY_CURRENT_SUCCESS,
      moodSurveyCurrent: data,
    });
  } catch (error) {
    yield put({
      type: Types.GET_MOOD_SURVEY_CURRENT_FAIL,
      ...error.response.data,
    });
  }
}

function* postMoodSurveyAnswer({ payload }) {
  const { idMoodSurvey, answerKind, anonymous } = payload;
  const formData = new FormData();
  formData.append("answer_kind", answerKind);
  formData.append("anonymous", anonymous);

  try {
    yield API_V2({
      url: `${POST_MOOD_SURVEY_ANSWER(idMoodSurvey)}`,
      method: "POST",
      data: formData,
    });

    yield put({
      type: Types.POST_MOOD_SURVEY_ANSWER_SUCCESS,
      userAnswer: true,
      moodSurveyPreview: {},
    });
  } catch (error) {
    yield put({
      type: Types.POST_MOOD_SURVEY_ANSWER_FAIL,
      ...error.response.data,
    });
  }
}

function* visualizationMoodSurveyCurrent({ payload }) {
  const { idMoodSurvey, idUser } = payload;

  const formData = new FormData();
  formData.append("user_id", idUser);

  try {
    const data = yield API_V2({
      url: `${VISUALIZATION_MOOD_SURVEY_CURRENT(idMoodSurvey)}`,
      method: "POST",
      data: formData,
    });
  } catch (error) {
    yield put({
      type: Types.POST_MOOD_SURVEY_ANSWER_FAIL,
      ...error.response.data,
    });
  }
}

function* getMoodSurveyFromProfileAPI() {
  try {
    const { data } = yield axios({
      url: `https://base-api.prd.appnoz.com.br/api/v2/clients?team=${style.names.nameApp}`,
      method: "GET",
    });

    yield put({
      type: Types.GET_MOOD_SURVEY_FROM_PROFILE_API_SUCCESS,
      hasMoodSurveyActive: data.profile.mood_survey,
    });

    sessionStorage.setItem("@Noz-hasMoodSurvey", data.profile.mood_survey);
  } catch (error) {
    yield put({
      type: Types.GET_MOOD_SURVEY_FROM_PROFILE_API_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_USER_REQUEST, getUserRequest);
  yield takeLatest(Types.GET_USER_TO_EDIT_REQUEST, getUserToEditRequest);
  yield takeLatest(Types.GET_TERMS, getTerms);
  yield takeLatest(Types.GET_PRIVACY_POLICIES, getPrivacyPolicies);
  yield takeLatest(Types.GET_NOT_VIEWD_NOTIFICATIONS, getNotViewdNotifications);
  yield takeLatest(Types.UPDATE_DATA_USER, updateDataUser);
  yield takeLatest(Types.UPDATE_PASSWORD, updatePassword);
  yield takeLatest(Types.GET_PERSONAL_QUESTIONS, getPersonalQuestions);
  yield takeLatest(Types.GET_TUTORIAL_TOKEN, getTutorialToken);
  yield takeLatest(Types.DELETE_USER_ACCOUNT, deleteUserAccount);
  yield takeLatest(Types.GET_USER_ANSWER, getUserAnswer);
  yield takeLatest(Types.GET_MOOD_SURVEY_CURRENT, getMoodSurveyCurrent);
  yield takeLatest(Types.POST_MOOD_SURVEY_ANSWER, postMoodSurveyAnswer);
  yield takeLatest(
    Types.VISUALIZATION_MOOD_SURVEY_CURRENT,
    visualizationMoodSurveyCurrent,
  );
  yield takeLatest(
    Types.GET_MOOD_SURVEY_FROM_PROFILE_API,
    getMoodSurveyFromProfileAPI,
  );
}
